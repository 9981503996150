if (typeof window.onResourcesAndDOMReady !== 'function') {
    if (typeof window.resourcesAndDOMReady === typeof undefined) {
        window.resourcesAndDOMReady = false;
    }

    window.onResourcesAndDOMReady = function (callback) {
        if (!window.resourcesAndDOMReady) {
            document.addEventListener('resourcesAndDOMReady', function () {
                setTimeout(() => {
                    callback();
                }, 0);
            });
        }
        else {
            setTimeout(() => {
                callback();
            }, 0);
        }
    }
}

if (typeof window.addScriptToQueue !== 'function') {
    window.useScriptQueue = false;

    window.addScriptToQueue = function (callback, delay = 0) {
        if (window.useScriptQueue) {
            setTimeout(() => {
                callback();
            }, delay);
        } else {
            callback();
        }
    }
}

addScriptToQueue(() => {
    window.jQuery = require('jquery');
    window.jQ = window.$ = window.jQuery;
}, 0);

// addScriptToQueue(() => {
//     require('jquery-migrate');
//     window.jQuery.migrateMute = true;
//     window.jQ = window.$ = window.jQuery;
// }, 0);
